import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Navbar, { isSupervisor: _ctx.isSupervisor }, null, 8, ["isSupervisor"]),
    _createVNode(_component_router_view, {
      userId: _ctx.userId,
      divisionId: _ctx.divisionId,
      userName: _ctx.userName,
      isSupervisor: _ctx.isSupervisor,
      profile: _ctx.profile
    }, null, 8, ["userId", "divisionId", "userName", "isSupervisor", "profile"])
  ]))
}